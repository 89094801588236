import { reactive } from '@vue/composition-api'
import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_INVENTORY = { action: 'change', subject: 'Inventory' }

  const orderColumns = reactive([
    {
      key: 'actions',
      label: '',
      thStyle: {
        width: '5%',
        padding: '0',
      },
    },
    {
      key: 'order_id',
      label: 'Order No.',
      isSortable: true,

    },
    {
      key: 'state',
      label: 'State',

    },
    {
      key: 'order_date',
      label: 'Order Date',
      isSortable: true,

    },
    {
      key: 'ship_by',
      label: 'Ship By',
      isSortable: true,

    },
    {
      key: 'return_by',
      label: 'Return by',
      isSortable: true,

    },
  ])

  const productColumns = [
    {
      key: 'actions',
      label: '',

    },
    {
      key: 'sku',
      label: 'SKU',

    },
    {
      key: 'name',
      label: 'Name',

    },
    {
      key: 'invoice',
      label: 'Invoice',

    },
    {
      key: 'quantity',
      label: 'Quantity',

    },
    {
      key: 'paid',
      label: 'Paid',

    },
  ]

  return {
    orderColumns,
    productColumns,
    ACCESS_ABILITY_INVENTORY,
  }
}
