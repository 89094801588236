<template>
  <div>
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <div>
      <h1 class="text-center font-medium-5 font-weight-bolder">
        Order History
      </h1>
      <div class="float-right">
        <feather-icon
          v-if="customer.customer_detail.allow_to_purchase_from_supplier"
          v-b-tooltip.noninteractive.hover
          icon="LPOIcon"
          :title="$t('Create a RFQ to all registered suppliers')"
          class="cursor-pointer"
          size="37"
          @click="goToPO()"
        />
      </div>
      <b-table
        :fields="orderColumns"
        :items="transactionsByOrder"
        show-empty
        striped
        responsive
        :empty-text="$t('None found')"
      >

        <template #cell(actions)="data">

          <Can
            :i="ACCESS_ABILITY_INVENTORY.action"
            :a="ACCESS_ABILITY_INVENTORY.subject"
          >
            <div class="d-flex align-items-center inventory-list-catalog-table__actions">
              <b-link
                class="text-body text-wrap"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="LEyeIcon"
                    size="24"
                    class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                  />
                </div>
              </b-link>
            </div>
          </Can>
        </template>
        <template #cell(state)="data">
          <status-filter-table :item="data.item" />
        </template>
        <template #cell(order_date)="data">
          {{ data.item.order_date ? mapDate(data.item.order_date) : '---' }}
        </template>
        <template #empty="">
          <div class="text-center">
            {{ $t('None found') }}
          </div>
        </template>

      </b-table>
    </div>
    <div>
      <h1 class="text-center font-medium-5 font-weight-bolder">
        Product History
      </h1>
      <b-table
        :module-name="MODULE_NAME"
        :fields="productColumns"
        :items="transactionsByProduct"
        show-empty
        striped
        responsive
        :empty-text="$t('None found')"
      >
        <template #cell(actions)="data">
          <div>
            <Can
              :i="ACCESS_ABILITY_INVENTORY.action"
              :a="ACCESS_ABILITY_INVENTORY.subject"
            >
              <div class="d-flex align-items-center inventory-list-catalog-table__actions">
                <b-link
                  class="text-body text-wrap"
                >
                  <feather-icon
                    icon="LEyeIcon"
                    size="24"
                    class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                  />
                </b-link>
              </div>
            </Can>
          </div>
        </template>
        <template #cell(invoice)="data">
          {{ data.item.invoice }}
          <feather-icon
            icon="LEyeIcon"
            size="24"
            class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
          />
        </template>
        <template #empty="">
          <div class="text-center">
            {{ $t('None found') }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BLink, BTable, VBTooltip } from 'bootstrap-vue'
import StatusFilterTable from '@/views/main/orders/view/purchase/components/list/StatusFilterTable.vue'
import { getValueFromGivenObjectByKey, mapDate } from '@/@core/utils/utils'
import config from './config'
import CustomerName from '../../components/CustomerName.vue'

export default {
  name: 'SalesByOrder',
  components: {
    StatusFilterTable,
    BLink,
    BTable,
    CustomerName,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  computed: {
    transactionsByOrder() {
      return this.$store.state[this.MODULE_NAME].customer?.transactions?.orders ?? []
    },
    transactionsByProduct() {
      return this.$store.state[this.MODULE_NAME].customer?.transactions?.products ?? []
    },
    customer() {
      return this.$store.state[this.MODULE_NAME_CUSTOMER].customer
    },
    customerID() {
      return this.$route.params.id
    },
  },
  created() {
    this.$emit('updateMenu', 'home-customers-sls-by-order')
  },
  methods: {
    goToPO() {
      this.$router.push({ name: 'home-orders-purchase-cr-create', query: { supplier_id: `${this.customerID}` } })
    },
  },
  setup() {
    const MODULE_NAME_CUSTOMER = 'customers'

    const MODULE_NAME = 'customers'
    const { orderColumns, productColumns, ACCESS_ABILITY_INVENTORY } = config()
    return {
      orderColumns, productColumns, MODULE_NAME, ACCESS_ABILITY_INVENTORY, MODULE_NAME_CUSTOMER, mapDate, getValueFromGivenObjectByKey,
    }
  },
}
</script>

<style scoped>

</style>
